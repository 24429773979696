import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import shopping_cart from '../../images/services/shopcart-01 1.png'
import payment_gateway from '../../images/services/payment_gateway 1.png'
import InventoryMgt from '../../images/services/InventoryMgt 1.png'
import order_mng from '../../images/services/OrderMgt-01 1.png'
import crm from '../../images/services/crm2-01 1.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"
import FeaturedWork from "../../components/MOB_FeaturedWork"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Comprehensive E-Commerce Solutions to Boost Your Online Sales</Title>
              <br />
              <p>
              Discover our tailored e-commerce solutions
              designed to enhance your online presence and maximize sales.  <br />
                <br />
                From intuitive website design to effective SEO strategies,
                we provide everything you need to succeed in the competitive
                digital marketplace. Start transforming your e-commerce business today!
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>Ecommerce Solution and integration</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={shopping_cart} img_alt={'shopping_cart'} card_heading={'Shopping Cart'} card_para={'Enhance the ecommerce experience for both merchants and customers. Allowing customers to add multiple items to their cart and complete their transactions in a single session.'}/>
        <ServiceCard img_src={payment_gateway} img_alt={'payment_gateway'} card_heading={'Payment Gateways'} card_para={'Essential tools for facilitating online transactions, offering numerous benefits to businesses and customers alike. By automating the payment process, payment gateways significantly speed up transaction times compared to manual processing.'}/>
        <ServiceCard img_src={InventoryMgt} img_alt={'InventoryMgt'} card_heading={'Inventory Management System'} card_para={'A crucial tool for businesses to efficiently manage their inventory levels, orders, sales, and deliveries. Maintain accurate stock levels by automating tracking and reporting.'}/>
        <ServiceCard img_src={order_mng} img_alt={'order_mng'} card_heading={'Order Management System'} card_para={'A vital tool for businesses that manage sales and inventory across multiple channels. An OMS automates the entire order fulfillment process, from order placement to shipping.'}/>
        <ServiceCard img_src={crm} img_alt={'crm'} card_heading={'CRM'} card_para={'CRMs centralize customer data, enabling businesses to understand their customers better and tailor interactions accordingly. All team members have access to the same customer information, which improves internal communication and collaboration.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      {/* <div className="featured-projects">
        <FeaturedWork data={data} />
      </div> */}
      <DevelopmentProcess title='Ecommerce Solution and integration Services Process' >
        <ServiceStepsProcess process_heading='Discovery' process_para="A structured meeting designed to align stakeholders on the objectives, requirements, and potential challenges of an ecommerce project." process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Theme Selection' process_para="Selecting the right theme for an ecommerce system is crucial for creating an engaging and effective online store." process_step_number="2" />
        <ServiceStepsProcess process_heading='Environment Setup' process_para="Preparing the necessary hardware and software environments. Ensure the platform allows for customization to match your brand's identity and functional requirements." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='User Acceptance Testing (UAT)' process_para="Final validation by end-users to ensure the application meets their needs. Gathering feedback from users to confirm that the application is ready for production." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='Go-Live' process_para="Going live with your ecommerce system is a significant milestone that requires careful planning and execution." process_step_number="5" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Post Go-Live Support' process_para="Going live with an ecommerce system is a critical phase that requires effective support to ensure a smooth transition and ongoing operations." process_step_number="6" />
      </DevelopmentProcess>
    </Layout>
  )
}

export default Index

export const  e_commerceSolution= graphql`
  query e_commerceSolution {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
